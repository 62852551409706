import { VSpacer } from 'vuetify/lib/components/VGrid';

import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.string.link.js";
var render = function render() {
  var _vm$item, _vm$item$shopify_prod, _vm$item$shopify_prod2, _vm$item$shopify_prod3;
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "d-flex"
  }, [_c('CoverImage', {
    staticClass: "tw-flex-[0_0_auto] !tw-size-[120px] tw-rounded-lg",
    attrs: {
      "src": (_vm$item = _vm.item) === null || _vm$item === void 0 ? void 0 : (_vm$item$shopify_prod = _vm$item.shopify_product) === null || _vm$item$shopify_prod === void 0 ? void 0 : (_vm$item$shopify_prod2 = _vm$item$shopify_prod.images) === null || _vm$item$shopify_prod2 === void 0 ? void 0 : _vm$item$shopify_prod2[0],
      "dummy": _vm.dummy
    }
  }), _vm._v(" "), _c('div', {
    staticClass: "item-detail d-flex flex-column ml-6",
    staticStyle: {
      "width": "100%",
      "min-width": "0"
    }
  }, [_vm.dummy ? _c('SkeletonLoader', {
    staticStyle: {
      "height": "auto"
    },
    attrs: {
      "text": "subtitle-1",
      "width": "160"
    }
  }) : _vm.link ? _c('LinkText', {
    staticClass: "item-title text-subtitle-1 mt-1",
    attrs: {
      "to": _setup.isLineItem(_vm.item) ? "/touch/".concat(_vm.item.touch) : "/touch/".concat(_vm.item.id)
    }
  }, [_vm._v("\n      " + _vm._s(_vm.item.name) + "\n    ")]) : _c('span', {
    staticClass: "item-title text-subtitle-1 mt-1"
  }, [_vm._v("\n      " + _vm._s(_vm.item.name) + "\n    ")]), _vm._v(" "), _vm.dummy ? [_c('SkeletonLoader', {
    staticClass: "mt-1",
    staticStyle: {
      "height": "auto"
    },
    attrs: {
      "text": "body-1",
      "width": "160"
    }
  }), _vm._v(" "), _c(VSpacer), _vm._v(" "), _c('SkeletonLoader', {
    staticClass: "mb-1",
    staticStyle: {
      "height": "auto"
    },
    attrs: {
      "text": "body-1",
      "width": "40"
    }
  })] : ((_vm$item$shopify_prod3 = _vm.item.shopify_product) === null || _vm$item$shopify_prod3 === void 0 ? void 0 : _vm$item$shopify_prod3.product_status) === 'ACTIVE' ? [_c('span', {
    staticClass: "item-description text-body-1 mt-1"
  }, [_vm._v("\n        " + _vm._s(_vm.item.shopify_product.variant_title) + "\n      ")]), _vm._v(" "), _c(VSpacer), _vm._v(" "), _vm.item.attachments ? _c('AttachmentIcon', {
    staticClass: "tw-mb-2",
    attrs: {
      "label": "",
      "attachments": _vm.item.attachments
    }
  }) : _vm._e(), _vm._v(" "), _vm.countContacts === 1 ? _c('span', {
    staticClass: "primary-text--text text-body-1 mt-1"
  }, [_vm._v("\n        " + _vm._s(_vm.$price(_vm.item.shopify_product.variant_price)) + "\n        "), _vm._t("text-prefix")], 2) : _c('span', {
    staticClass: "primary-text--text text-body-1 mt-1"
  }, [_vm._v("\n        " + _vm._s("".concat(_vm.$price(_vm.item.shopify_product.variant_price), " x ").concat(_vm.$t('persons', [_vm.countContacts]), " = ")) + "\n        "), _c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v(_vm._s(_vm.$price(_setup.totalPrice)))]), _vm._v(" "), _vm._t("text-prefix")], 2)] : [_c('span', {
    staticClass: "item-description text-body-1 mt-1 error--text"
  }, [_vm._v("\n        " + _vm._s(_vm.$t('error.notFound.product')) + "\n      ")])]], 2)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };