/* unplugin-vue-components disabled */import { render, staticRenderFns } from "./check.vue?vue&type=template&id=7e6f20df"
import script from "./check.vue?vue&type=script&lang=js"
export * from "./check.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Loading: require('/code/src/components/Loading.vue').default,SectionSubTitle: require('/code/src/components/text/SectionSubTitle.vue').default,TouchSummary: require('/code/src/components/widgets/TouchSummary.vue').default,AddressLabel: require('/code/src/components/widgets/AddressLabel.vue').default,OutlinedTable: require('/code/src/components/table/OutlinedTable.vue').default,AddressAlert: require('/code/src/components/widgets/AddressAlert.vue').default,Input: require('/code/src/components/input/Input.vue').default,TouchTextViewer: require('/code/src/components/widgets/TouchTextViewer.vue').default,LabelText: require('/code/src/components/text/LabelText.vue').default,GiftPageEditor: require('/code/src/components/widgets/GiftPageEditor.vue').default,ContentArea: require('/code/src/components/ContentArea.vue').default,TextButton: require('/code/src/components/button/TextButton.vue').default})
